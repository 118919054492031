<template>
  <div
    class="CardSemiFlip">
    <Card
      :class="{ active: isActive || !isBigScreen }"
      @mouseleave.native="isActive = false"
      @mouseover.native="isActive = true">
      <Row>
        <Column :lg="6">
          <div class="details">
            <h3>{{ card.label }}</h3>
            <p v-if="isActive || !isBigScreen">
              {{ card.text }}
            </p>
            <Icon v-if="!isActive && isBigScreen" :name="card.icon" />
          </div>
        </Column>
        <Column :lg="6">
          <div class="image">
            <Pic :src="card.image" />
          </div>
        </Column>
      </Row>
    </Card>
    <!-- <Card v-show="isActive" class="details">
      <Pic :src="card.background" />
      <div class="imgOverlay" />
      <div class="content">
        <h3>{{ card.label }}</h3>
        <p>{{ card.text }}</p>
      </div>
    </Card> -->
  </div>
</template>

<script>
import { getters } from 'views/utils'

export default {
  props: {
    card: Object,
  },
  data() {
    return {
      isActive: false,
    }
  },
  computed: {
    isBigScreen() {
      return this.screenSizes.includes('lg')
    },
    ...getters('screenSizes'),
  },
}
</script>

<style lang="scss">
.CardSemiFlip {
  background: $white;
  border-radius: $radius 0;

  > .Card {
    padding: 0;

    .details {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 3rem 2rem 0 3rem;
      // @include lg {
      //   padding: 1rem 2rem;
      // }

      p {
        line-height: $lh;
      }

      .Icon {
        color: $blue;
        font-size: 6rem;
      }
    }

    .image {
      border-radius: $radius 0;
      height: 20rem;
      overflow: hidden;

      img {
        filter: grayscale(100%) brightness(0.5);
        height: 100%;
        object-fit: cover;
        width: 100%;
      }
    }

    h3 {
      font-weight: $bold;
      margin-bottom: 2rem;
    }

    &.active {
      img {
        filter: none;
      }
    }
  }
}
</style>
