<template>
  <div id="HowItWorks">
    <Hero src="../assets/howItWorks/hero.jpg">
      <h1>Kaip tai veikia?</h1>
    </Hero>
    <SectionReasons />
    <SectionOrderSteps />
    <FormSignupWide v-if="!isAuth" />
  </div>
</template>

<script>
import FormSignupWide from './components/FormSignupWide'
import Hero from '../components/landing/Hero'
import Meta from '../mixins/Meta'
import SectionOrderSteps from './components/SectionOrderSteps'
import SectionReasons from './components/SectionReasons'

export default {
  mixins: [
    Meta(
      {
        inner: 'Kaip tai veikia?',
        separator: '|',
        complement: 'Saulės bendruomenė',
      },
      undefined,
      require('@/assets/social/homepage.jpg'),
    ),
  ],
  components: {
    Hero,
    SectionReasons,
    SectionOrderSteps,
    FormSignupWide,
  },
  data() {
    return {
      posts: null,
    }
  },
}
</script>

<style lang="scss">
#HowItWorks {
  background: $white-ter;

  .Hero {
    @include roundSvg('../../assets/ellipse.svg');

    h1 {
      @include dotBefore(8rem);
    }

    img {
      // filter: brightness(0.2);
      filter: grayscale(80%) brightness(0.2);
    }
  }

  .Sect {
    margin-top: 4rem;

    &:first-of-type {
      margin-top: 0;
    }
  }

}

</style>
