<template>
  <div class="FormSignupWide">
    <Wrap>
      <FormFloat class="small-pad" @submit="signup">
        <transition name="slide-down">
          <Notice v-if="message" :class="message.theme">
            {{ message.text }}
          </Notice>
        </transition>
        <!-- <FieldManualCaptcha :value.sync="manualCaptcha" /> -->

        <FormUser
          :type="formType"
          :user="user" />

        <template v-if="isSignupOnReservation">
          <FieldSkipReservation
            v-if="project && project.canSkipReservation"
            :value="hasSkippedReservation"
            @update:value="$up('hasSkippedReservation', $event)" />

          <Field
            data-cy="promo"
            label="Nuolaidos kodas (Promo)"
            :maxlength="50"
            :value.sync="promoCode" />

          <Field
            label="Valstybės parama"
            :options="apvaOptions"
            required
            type="select"
            :value.sync="apvaChoice" />
          <Field
            v-if="apvaChoice === 'HAS_APVA' && apvaStageOptions.length > 1"
            label="Pasirinkite APVA etapą"
            :options="apvaStageOptions"
            :required="apvaChoice === 'HAS_APVA'"
            type="select"
            :value.sync="apvaStageId" />
        </template>

        <!-- <NoticeRecaptcha /> -->

        <div class="actions">
          <Btn
            class="arrow signinCta invert"
            :isLoading="isSigningUp"
            type="submit">
            Registruotis
          </Btn>
          <Route class="Btn arrow minimalistic invert" to="Login">
            Jau turite paskyrą? Prisijukite čia
          </Route>
        </div>
      </FormFloat>

      <!-- <SocialLogin v-if="!isSignupOnReservation" /> -->
    </Wrap>
  </div>
</template>

<script>
import { actions, getters } from '../../utils'
import { APVA_CHOICES } from '@/store/consts'
import FieldSkipReservation from 'components/fields/FieldSkipReservation'
import FormFloat from 'views/components/FormFloat'
import FormUser from './FormUserWide'
import Message from '../../mixins/Message'
import Recaptcha from '../../mixins/Recaptcha'
// import SocialLogin from 'views/components/SocialLogin'
import { softThrow } from '../../../bugsnag'

export default {
  mixins: [
    Message,
    Recaptcha,
  ],
  components: {
    FieldSkipReservation,
    FormFloat,
    FormUser,
    // SocialLogin,
  },
  props: {
    formType: {
      type: String,
      default: 'signup',
    },
    title: {
      type: String,
      default: 'Registracija',
    },
    hasSkippedReservation: Boolean,
  },
  data() {
    return {
      user: {},
      isSigningUp: false,
      promoCode: null,
      apvaChoice: Object.keys(APVA_CHOICES)[0],
      apvaStageId: null,
      apvaOptions: Object.entries(APVA_CHOICES),
    }
  },
  computed: {
    apvaStageOptions() {
      return [
        [null, 'Pasirinkite etapą'],
        ...this.query('apvaStage', {
          isHidden: false,
          $sort: { startDate: -1 },
        })
          .map(apvaStage => [
            apvaStage.id,
            apvaStage.startDate
              .toLocaleString('lt-LT', { year: 'numeric', month: 'long', day: 'numeric' }),
          ]),
      ]
    },
    amount() {
      return Number(this.$route.params.amount) / 100
    },
    isSignupOnReservation() {
      return this.formType === 'reserve'
    },
    project() {
      return this.query('project', this.$route.params.id)
    },
    ...getters('referrers', 'userFactory'),
  },
  methods: {
    async signup() {
      try {
        this.isSigningUp = true

        this.SET_SIGNUP_EMAIL(this.user.email)
        await this.SIGNUP(await this.injectRecaptcha({
          ...this.user,
          metaReferrers: this.referrers,
          ...this.isSignupOnReservation ? {
            reservationPresignup: {
              projectId: Number(this.$route.params.id),
              capacity: this.amount,
              promoCode: this.promoCode,
              hasSkippedReservation: this.hasSkippedReservation,
              apvaChoice: this.apvaChoice,
              apvaStageId: this.apvaStageId,
            },
          } : {},
        }, 'signup'))

        try {
          this.$ga.event('Signup', 'submit', 'email')
          this.$analytics.fbq.event('CompleteRegistration')
          if (this.user.hasSubscribed) {
            this.$ga.event('Newsletter', 'subscribe')
          }
        } catch (error) {
          softThrow(error)
        }

        this.$router.push({ name: 'SignupThanks' })
      } catch (error) {
        this.setErrorMessage(error)
        if (!error.data || !error.data.name) throw error
      } finally {
        this.isSigningUp = false
      }
    },
    ...actions(
      'SET_SIGNUP_EMAIL',
      'SIGNUP',
      'FETCH_APVA_STAGE',
    ),
  },
  beforeMount() {
    this.LOAD('FETCH_APVA_STAGE')
    this.user = this.userFactory({
      passwordMatch: '',
      code: null,
    })
  },
}
</script>

<style lang="scss">
.FormSignupWide {
  background: linear-gradient(0deg, rgba($blue, 0.8), rgba($blue, 0.8)), url('../../../assets/index/panels.jpg');
  color: $white !important;

  > .Wrap .FormFloat .inner {
    color: $white;

    .signinCta {
      width: 15rem;
      @include md {
        width: 20rem;
      }
    }
  }

  .actions {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    > .Btn {
      width: 20rem;
    }
  }

  .Field {
    label {
      color: $white;
    }

    // input {
    //   background: transparent;
    // }
  }
}
// .FormSect.lg-no-border {
//   @include lg {
//     border: 0;
//   }
// }
</style>
