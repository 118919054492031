<template>
  <div>
    <Row>
      <Column :md="6">
        <Field
          autocomplete="given-name"
          :disabled="canNotEdit"
          label="Vardas"
          name="first-name"
          required
          :value.sync="user.firstName" />
      </Column>
      <Column :md="6">
        <Field
          autocomplete="family-name"
          :disabled="canNotEdit"
          label="Pavardė"
          name="last-name"
          required
          :value.sync="user.lastName" />
      </Column>
    </Row>
    <Row>
      <Column :md="6">
        <Field
          autocomplete="email"
          :disabled="!['signup', 'admin', 'reserve'].includes(type)"
          label="El. paštas"
          required
          rules="email"
          type="email"
          :value.sync="user.email" />
      </Column>
      <Column :md="6">
        <Field
          autocomplete="tel"
          :disabled="isPhoneFieldDisabled"
          label="Telefono numeris"
          :maxlength="18"
          :minlength="8"
          :required="type !== 'admin'"
          :value.sync="user.phone" />
      </Column>
    </Row>
    <template v-if="type !== 'signup'">
      <Row>
        <Column :md="4">
          <Field
            autocomplete="street-address"
            :disabled="canNotEdit"
            label="Adresas"
            :maxlength="92"
            :minlength="8"
            :required="areFieldsRequired"
            type="text"
            :value.sync="user.address" />
        </Column>
        <Column :md="4">
          <Field
            autocomplete="city"
            :disabled="canNotEdit"
            label="Miestas"
            :maxlength="92"
            :minlength="4"
            :required="areFieldsRequired"
            type="text"
            :value.sync="user.city" />
        </Column>
        <Column :md="4">
          <Field
            autocomplete="postal-code"
            :disabled="canNotEdit"
            label="Pašto kodas"
            :maxlength="5"
            :minlength="5"
            :required="areFieldsRequired"
            type="text"
            :value.sync="user.postCode" />
        </Column>
      </Row>
      <Field
        autocomplete="off"
        data-cy="personCode"
        :disabled="canNotEdit"
        label="Asmens kodas"
        :maxlength="11"
        :minlength="11"
        :required="areFieldsRequired"
        type="number"
        :value.sync="user.personCode" />
      <Field
        autocomplete="off"
        data-cy="iban"
        label="Sąskaitos numeris (IBAN formatu)"
        :maxlength="40"
        :minlength="12"
        placeholder="LT601010..."
        :required="areFieldsRequired"
        :value.sync="user.iban" />
      <Field
        data-cy="isCompany"
        :disabled="canNotEdit"
        label="Noriu rezervuoti kaip juridinis asmuo (įmonė)"
        type="checkbox"
        :value.sync="user.isCompany" />
      <Row v-if="user.isCompany">
        <Column :md="6">
          <Field
            label="Įmonės pavadinimas"
            :maxlength="60"
            :minlength="1"
            name="company-name"
            required
            :value.sync="user.companyName" />
        </Column>
        <Column :md="6">
          <Field
            :disabled="canNotEdit"
            label="Įmonės kodas"
            :maxlength="9"
            :minlength="7"
            name="company-code"
            required
            rules="companyCode"
            :value.sync="user.companyCode" />
        </Column>
        <Column :md="6">
          <Field
            label="PVM kodas"
            :maxlength="20"
            :minlength="5"
            name="company-vat-code"
            rules="companyVatCode"
            :value.sync="user.companyVatCode" />
        </Column>
        <Column :md="6">
          <Field
            label="Atstovo pareigos"
            :maxlength="60"
            :minlength="1"
            name="company-representative-role"
            required
            :value.sync="user.companyRepresentativeRole" />
        </Column>
      </Row>
    </template>
    <template v-if="type !== 'order'">
      <ValidationObserver v-if="isSigningUp || (type === 'edit' && hasPasswordChange)">
        <Field
          v-if="type === 'edit'"
          autocomplete="current-password"
          label="Dabartinis slaptažodis"
          :maxlength="64"
          :minlength="8"
          name="current-password"
          required
          rules="password"
          type="password"
          :value.sync="user.passwordCurrent" />
        <Row>
          <Column :lg="6">
            <Field
              autocomplete="new-password"
              :label="passwordLabel"
              :maxlength="64"
              :minlength="8"
              name="password"
              :required="isSigningUp"
              rules="password|passwordNumber|passwordNotAllNumeric"
              type="password"
              :value.sync="user.password" />
          </Column>
          <Column :lg="6">
            <Field
              autocomplete="new-password"
              :label="`${passwordLabel} (dar kartą)`"
              :required="!!user.password"
              rules="passwordMatch:password"
              type="password"
              :value.sync="user.passwordMatch" />
          </Column>
        </Row>
      </ValidationObserver>
      <template v-if="type === 'admin'">
        <Row>
          <Column :md="6">
            <Field
              label="Vartotojo rolė"
              :options="roleOptions"
              type="select"
              :value.sync="user.role" />
          </Column>
          <!--
          <Column :md="6">
            <Field
              label="Ar naudojo validavimo kodą"
              type="checkbox"
              :value.sync="user.hasManualCaptcha" />
          </Column>
          -->
        </Row>
        <Field
          label="Užrašas apie vartotoją"
          type="textarea"
          :value.sync="user.note" />
        <Btn v-if="!user.isVerified && type === 'admin'" @click="verifyUser">
          Patvirtinti vartotoją
        </Btn>
      </template>
      <template v-else>
        <FieldConsent :value.sync="user.hasAcceptedPolicy" />
        <FieldSubscribe :value.sync="user.hasSubscribed" />

        <!-- <Field-subscribe :value.sync="user.hasSubscribed"/> -->
      </template>
    </template>
  </div>
</template>

<script>
import { actions, getters } from 'views/utils'
import FieldConsent from 'views/components/FieldConsent'
import FieldSubscribe from 'views/components/FieldSubscribe'
import Message from 'mixins/Message'

export default {
  mixins: [Message],
  components: { FieldConsent, FieldSubscribe },
  props: {
    hasPasswordChange: Boolean,
    isCompanyAlready: Boolean,
    isPhoneFieldDisabled: Boolean,
    type: String,
    user: Object,
  },
  computed: {
    isSigningUp() {
      return this.type === 'signup' || this.type === 'reserve'
    },
    areFieldsRequired() {
      return this.type === 'order' || this.type === 'reserve'
    },
    canNotEdit() {
      if (!this.isAuth || (this.authUser.id !== this.user.id)) return false

      return this.hasContractsActive
    },
    hasContractsActive() {
      return this
        .query('contract', {
          cancelledAt: null,
          userId: this.user.id,
        })
        .length > 0
    },
    passwordLabel() {
      return this.type === 'edit'
        ? 'Naujas slaptažodis'
        : 'Slaptažodis'
    },
    ...getters('roleOptions'),
  },
  methods: {
    async verifyUser() {
      await this.PATCH_AUTHMANAGEMENT({
        id: this.user.id,
        verifyToken: null,
      })

      this.SUCCESS('Vartotojas patvirtintas')
    },
    ...actions('PATCH_AUTHMANAGEMENT'),
  },
}
</script>
