<template>
  <Sect id="SectionOrderSteps">
    <Wrap class="lg">
      <h2>Kaip užsisakyti dalį nutolusios saulės elektrinės?</h2>
      <Row>
        <Column
          v-for="(step, index) in steps"
          :key="index"
          class="item"
          :lg="3">
          <div class="stepNo">
            0{{ index + 1 }}
          </div>
          <h3 class="label">
            {{ step.label }}
          </h3>
          <p>
            {{ step.text }}
          </p>
        </Column>
      </Row>
    </Wrap>
  </Sect>
</template>

<script>
export default {
  data() {
    return {
      steps: [
        {
          label: 'Registruokitės',
          text: 'Siųskite užklausą, registruokitės ir tapkite saulės bendruomenės nariu',
        },
        {
          label: 'Rezervuokite',
          text: 'Savitarnos sistemoje rezervuokite savo nutolusios elektrinės dalį',
        },
        {
          label: 'Atsipalaiduokite',
          text: 'Sekite projekto eigą ir laukite elektrinės įrengimo termino. Galite atsipalaiduoti – apie visus įrengimo žingsnius informuosime',
        },
        {
          label: 'Vartokite',
          text: 'Tapkite atsinaujinančios energijos vartotoju',
        },
      ],
    }
  },
}
</script>

<style lang="scss">
#SectionOrderSteps {
  background: $black-bis;
  color: $white;
  padding-bottom: 4rem;
  // margin-bottom: 6rem;
  // margin-top: 6rem;
  padding-top: 6rem;

  h2 {
    @include dotAbove(1.5rem);
    color: $white;
  }

  .Row {
    align-items: flex-start;
  }

  .item {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem 0;

    .label {
      font-weight: $bold;
      margin-bottom: 2rem;
    }

    p {
      color: $white-ter;
      font-size: 1rem;
      padding: 0 2rem;
      text-align: center;
    }

    > .stepNo {
      color: $blue;
      font-size: 5rem;
      margin-bottom: 1rem;
    }
  }

}
</style>
